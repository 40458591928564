import './styles.scss';
import { cx } from '@emotion/css';

import {
  faBell,
  faFolder,
  faGear,
  faHandshake,
  faPercent,
  faShop,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import { useUserStore } from 'app/store';

import { RoutesPath } from 'shared/routes-path';

export const SidebarNav = () => {
  const { pathname } = useLocation();
  const { authorities } = useUserStore();

  const isAdmin = authorities.includes('ADMIN') ?? false;
  const isCallCenter = authorities.includes('CALL_CENTER') ?? false;

  return (
    <nav className="sidebar__nav">
      <ul className="nav">
        {isAdmin && (
          <>
            <Link
              className={cx('nav__item item', {
                active: pathname === RoutesPath.MERCHANTS,
              })}
              to={RoutesPath.MERCHANTS}
            >
              <FontAwesomeIcon icon={faShop} className="size-4" />
              <span className="item__title">Магазины</span>
            </Link>
            <Link
              className={cx('nav__item item', {
                active: pathname === RoutesPath.PRODUCTS,
              })}
              to={RoutesPath.PRODUCTS}
            >
              <FontAwesomeIcon icon={faPercent} className="size-4" />
              <span className="item__title">Продукты</span>
            </Link>
            <Link
              className={cx('nav__item item', {
                active: pathname === RoutesPath.PARTNERS,
              })}
              to={RoutesPath.PARTNERS}
            >
              <FontAwesomeIcon icon={faHandshake} className="size-4" />
              <span className="item__title">Партнеры</span>
            </Link>
          </>
        )}
        {(isAdmin || isCallCenter) && (
          <>
            <Link
              className={cx('nav__item item', {
                active: pathname === RoutesPath.ORDERS,
              })}
              to={RoutesPath.ORDERS}
            >
              <FontAwesomeIcon icon={faFolder} className="size-4" />
              <span className="item__title">Заказы</span>
            </Link>
            <Link
              className={cx('nav__item item', {
                active: pathname === RoutesPath.REQUESTS,
              })}
              to={RoutesPath.REQUESTS}
            >
              <FontAwesomeIcon icon={faBell} className="size-4" />
              <span className="item__title">Заявки</span>
            </Link>
          </>
        )}
        {isAdmin && (
          <Link className="nav__item item " to={RoutesPath.SETTINGS}>
            <FontAwesomeIcon icon={faGear} className="size-4" />
            <span className="item__title">Настройки</span>
          </Link>
        )}
      </ul>
    </nav>
  );
};
