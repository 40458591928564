import type { Order } from 'entities/order';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

export const DialogDetails = ({
  isOpen,
  onClose,
  data,
  detailId,
}: DialogCommonPropsWithData<Order> & { detailId: string }) => {
  const { details } = data.payments.find((payment) => payment.id === detailId)!;
  return (
    <DialogCommon title="Детализация" isOpen={isOpen} onClose={onClose}>
      {data.payments.length ? (
        <table className="mt-3 w-full text-left text-sm text-brand-1">
          <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {[
                'Статус',
                'Сумма платежа',
                'Номер карты',
                'Код ошибки',
                'Сообщение ошибки',
                'Детали ошибки',
              ].map((item) => (
                <th key={item} scope="col" className="px-6 py-3 text-center">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {details.map((detail) => {
              return (
                <tr
                  key={Math.random()}
                  className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                >
                  <td className="px-6 py-4 text-center">{detail.status}</td>
                  <td className="px-6 py-4 text-center">{detail.amount} ₽</td>
                  <td className="px-6 py-4 text-center">{detail.pan}</td>
                  <td className="px-6 py-4 text-center">{detail.errorCode}</td>
                  <td className="px-6 py-4 text-center">
                    {detail.errorMessage}
                  </td>
                  <td
                    className="px-6 py-4 text-center"
                    title={detail.errorDetails || ''}
                  >
                    Детали
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>История пуста</p>
      )}
    </DialogCommon>
  );
};
