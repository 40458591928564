import { Steps } from 'antd';

import { Description, DialogTitle, Field, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';

import { useModals } from 'features/view-order/hooks';
import { DialogPaymentsHistory } from 'features/view-order/ui';

import { OrderApi } from 'entities/order';
import { RequestApi } from 'entities/request/Request.api';
import { StatusTag } from 'entities/status';

import { Button, DialogSimple, Spinner } from 'shared/ui';

type ViewRequestDialogProps = {
  id: string | false;
  onClose: () => void;
};

export const ViewRequestDialog = ({ id, onClose }: ViewRequestDialogProps) => {
  const { modals, toggleModal } = useModals();

  const { data } = useQuery({
    queryFn: () => RequestApi.getRequest(id as string),
    queryKey: [OrderApi.KEY, 'getOrders', id],
  });

  if (!data) {
    return <Spinner />;
  }

  const renderData = [
    { title: 'Внутренний ID', value: data.internal_order_id },
    { title: 'Внешний ID', value: data.external_order_id },
    { title: 'ФИО', value: data.client_fio },
    { title: 'Общая сумма', value: data.sum },
    { title: 'Адрес доставки', value: data.delivery_address },
    { title: 'ID кредитного продукта', value: data.credit_product_id.name },
  ];

  return (
    <DialogSimple isOpen={!!id} onClose={onClose} className="w-full">
      <DialogTitle
        as="h3"
        className="flex items-center gap-3 text-xl font-medium text-white"
      >
        <span> Заявка № {data.internal_order_id}</span>
        <span className="text-xs text-gray-400">{data.date}</span>
        <StatusTag status={data.status} />
      </DialogTitle>
      <div className="mt-3 flex flex-col gap-4">
        <div className="grid grid-cols-[66.6666666666666%_1fr] gap-2">
          <div className="mt-3 grid h-fit grid-cols-2 gap-3">
            {renderData.map((item) => (
              <Field key={item.title}>
                <Label className="text-sm font-medium text-white">
                  {item.title}
                </Label>
                <Description className="text-sm/6 text-white/50">
                  {item.value}
                </Description>
              </Field>
            ))}
          </div>
          <Steps
            progressDot
            current={3}
            direction="vertical"
            items={data.payment_plan.map((item) => ({
              title: `${item.sum} ₽`,
              description: item.date,
            }))}
          />
        </div>
        <div className="mx-auto flex items-center gap-2">
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenGoods', true)}
          >
            Товары по заявке
          </Button>
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenPayments', true)}
          >
            График платежей
          </Button>
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenPaymentsHistory', true)}
          >
            История платежей
          </Button>
        </div>
      </div>
      <DialogSimple
        isOpen={modals.isOpenGoods}
        onClose={() => toggleModal('isOpenGoods', false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          Товары по заявке
        </DialogTitle>
        <div className="flex flex-col gap-4">
          <table className="w-full text-left text-sm text-brand-1">
            <thead className=" top-[20px] bg-brand-1 text-xs uppercase text-white">
              <tr>
                {[
                  'Номер',
                  'Категория',
                  'Артикль',
                  'Цена',
                  'Сумма',
                  'Не доставлено',
                  'Отменено',
                  'Выдано',
                  'Возват',
                ].map((item) => (
                  <th
                    key={item}
                    scope="col"
                    className="whitespace-nowrap px-6 py-3 text-center"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.cart.data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="h-fit cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 text-center">{item.id}</td>
                    <td className="px-6 py-4 text-center">{item.category}</td>
                    <td className="px-6 py-4 text-center">{item.article}</td>
                    <td className="px-6 py-4 text-center">{item.price} ₽</td>
                    <td className="px-6 py-4 text-center">{item.actual_sum} ₽</td>
                    <td className="px-6 py-4 text-center">
                      {item.qty_not_distributed}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {item.qty_canceled}
                    </td>
                    <td className="px-6 py-4 text-center">{item.qty_issued}</td>
                    <td className="px-6 py-4 text-center">
                      {item.qty_returned}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </DialogSimple>
      <DialogSimple
        isOpen={modals.isOpenPayments}
        onClose={() => toggleModal('isOpenPayments', false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          График платежей
        </DialogTitle>
        <table className="mt-3 w-full text-left text-sm text-brand-1">
          <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {['Номер платежа', 'Дата платежа', 'Сумма платежа'].map(
                (item) => (
                  <th scope="col" className="px-6 py-3" key={item}>
                    {item}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {data.payment_plan
              //@ts-ignore
              .sort((a, b) => +a.stage > +b.stage)
              .map((item) => {
                return (
                  <tr
                    key={item.step || item.stage}
                    className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                  >
                    {Object.values(item).map((item) => (
                      <td className="px-6 py-4 text-center">{item}</td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </DialogSimple>
      <DialogPaymentsHistory
        isOpen={modals.isOpenPaymentsHistory}
        onClose={() => toggleModal('isOpenPaymentsHistory', false)}
        toggleModal={toggleModal}
        data={data}
      />
    </DialogSimple>
  );
};
