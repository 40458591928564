import { axiosInstance } from 'shared/api/axios.defaults';

export class DocumentApi {
  static readonly KEY = 'documents';

  static async debpCertificate(orderId: string): Promise<File> {
    const { data } = await axiosInstance.get(
      `/documents/debt-certificate?orderId=${orderId}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }

  static async agreementPurchase(orderId: string): Promise<File> {
    const { data } = await axiosInstance.get(
      `/documents/agreement-purchase?orderId=${orderId}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }

  static async agreementPersonalData(orderId: string): Promise<File> {
    const { data } = await axiosInstance.get(
      `/documents/agreement-personal-data?orderId=${orderId}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }
}
