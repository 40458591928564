import { useQuery } from '@tanstack/react-query';
import type { SetFieldValue } from 'react-hook-form';

import { PartnerApi } from 'entities/partner';

import { SimpleSelect, Spinner } from 'shared/ui';

export const SelectPartner = ({
  setValue,
  defaultValue,
  valueKey,
}: {
  defaultValue?: string | number;
  setValue: SetFieldValue<any>;
  valueKey: string;
}) => {
  const { data } = useQuery({
    queryKey: [PartnerApi.KEY, 'getPartners'],
    queryFn: () => PartnerApi.getPartners(),
  });

  if (!data) return <Spinner className="size-4 fill-white text-white" />;

  const items = data.content.map((item) => ({
    id: item.id + '',
    label: item.name,
  }));

  const updatedDefaultValue = items.find((item) => +item.id === defaultValue);
  setValue(valueKey, updatedDefaultValue?.id ?? data.content[0].id);

  return (
    <SimpleSelect
      defaultValue={updatedDefaultValue}
      items={items}
      onChange={(value) => {
        setValue(valueKey, +value.id);
      }}
    />
  );
};
