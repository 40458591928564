import { cx } from '@emotion/css';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@headlessui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import type { DialogCommonPropsWithData } from 'features/view-order/ui/dialog-common';
import { DialogCommon } from 'features/view-order/ui/dialog-common';

import { CardApi } from 'entities/card';

import { onAxiosError } from 'shared/lib';
import { Button, Spinner } from 'shared/ui';

export const DialogPaySequentially = ({
  isOpen,
  onClose,
  data,
}: DialogCommonPropsWithData<{ id: string; client_id: string }>) => {
  const [selectedCardId, setSelectedCardId] = useState('');

  const client = useQueryClient();

  const { data: cards } = useQuery({
    queryKey: [CardApi.KEY, 'getCards', data.client_id],
    queryFn: () => CardApi.getCards(data.client_id),
    enabled: isOpen,
  });

  const paymentMutation = useMutation({
    mutationFn: () =>
      CardApi.charge({
        orderGuid: data.id,
        sum: 1,
        id: selectedCardId,
      }),
    onSuccess: () => {
      toast.success('Оплата прошла успешно');
      // client.refetchQueries({ queryKey: [CardApi.KEY, 'getProfile'] });
    },
    onError: onAxiosError,
  });

  const makeDefaultCardMutation = useMutation({
    mutationFn: (id: string) => CardApi.makeDefaultCard(id),
    onSuccess: () => {
      client.refetchQueries({ queryKey: [CardApi.KEY, 'getCards'] });
    },
    onError: onAxiosError,
  });

  useEffect(() => {
    if (!cards) return;
    const selectedCardId = cards.find((card) => card.isDefault)!.cardId;
    setSelectedCardId(selectedCardId);
  }, [cards]);

  if (!cards) return <Spinner />;

  const onClick = (id: string) => {
    if (selectedCardId !== id) setSelectedCardId(id);
  };

  return (
    <DialogCommon title="Способы оплаты" isOpen={isOpen} onClose={onClose}>
      <div className="mt-2 flex w-full flex-col gap-4 self-center">
        <div className="flex flex-col gap-4">
          {cards.map((card, index) => (
            <div className="flex items-center gap-1.5">
              <Button
                key={index}
                onClick={() => {
                  onClick(card.cardId);
                }}
                className={cx(
                  'className="inline-flex w-48 items-center justify-start  rounded bg-brand-2 py-1.5 text-sm/6 font-semibold text-white flex-center hover:bg-brand-2/90',
                  selectedCardId === card.cardId
                    ? 'bg-brand-main-1 text-white hover:text-white'
                    : '',
                )}
              >
                <div className="flex-center ">
                  {card.pan}
                  {selectedCardId === card.cardId && (
                    <Checkbox
                      checked={selectedCardId === card.cardId}
                      className="group size-6 fill-white flex-center"
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="hidden size-4 fill-white text-white group-data-[checked]:block"
                      />
                    </Checkbox>
                  )}
                </div>
              </Button>

              {!card.isDefault && (
                <p
                  className="cursor-pointer text-white underline"
                  onClick={() => makeDefaultCardMutation.mutate(card.cardId)}
                >
                  установить по умолчанию
                </p>
              )}
            </div>
          ))}
        </div>
        <hr />
        <div className="flex flex-col justify-center gap-2">
          <Button
            className="relative inline-flex w-full items-center justify-start  rounded bg-brand-2 py-1.5 text-sm/6 font-semibold text-white flex-center hover:bg-brand-2/90"
            onClick={() => paymentMutation.mutate()}
            disabled={paymentMutation.isPending}
          >
            Попробовать списать с выбранной карты
          </Button>
          <Button
            className="relative inline-flex w-full items-center justify-start  rounded bg-brand-2 py-1.5 text-sm/6 font-semibold text-white flex-center hover:bg-brand-2/90"
            onClick={onClose}
          >
            Отмена
          </Button>
        </div>
      </div>
    </DialogCommon>
  );
};
