import { cx } from '@emotion/css';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import type { InferType } from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  Input,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { DocumentApi } from 'entities/document';
import { FilesApi } from 'entities/files';
import type { Order } from 'entities/order';

import { genDownloadLink, onAxiosError } from 'shared/lib';
import { Button, Button as SharedButton } from 'shared/ui';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

const schema = yup.object({
  file: yup.mixed().required(),
});

export const DialogFiles = ({
  isOpen,
  onClose,
  data,
}: DialogCommonPropsWithData<{ id: string } & Order>) => {
  const client = useQueryClient();
  const [isOpenAddFile, setIsOpenAddFile] = useState(false);
  const [download, setDownload] = useState('');

  const { data: files } = useQuery({
    queryKey: [FilesApi.KEY, 'getFiles', data.id],
    queryFn: () => FilesApi.getFiles(data.id),
  });

  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    uploadFileMutation.mutate(data);
  };

  const uploadFileMutation = useMutation({
    mutationFn: (file: InferType<typeof schema>) =>
      FilesApi.upload({ ...file, id: data.id }),
    onSuccess: () => {
      toast.success('Успешно');
      client.refetchQueries({ queryKey: [FilesApi.KEY, 'getFiles', data.id] });
    },
    onError: onAxiosError,
  });

  const debtCertificateMutation = useMutation({
    mutationFn: () => DocumentApi.debpCertificate(data.id),
    onError: onAxiosError,
    onSuccess: (blob) => genDownloadLink(blob, `Справка о задолженности.pdf`),
  });

  const agreementPurchaseMutation = useMutation({
    mutationFn: () => DocumentApi.agreementPurchase(data.id),
    onError: onAxiosError,
    onSuccess: (blob) => genDownloadLink(blob, `Согласие на приобретение.pdf`),
  });

  const agreementPersonalDataMutation = useMutation({
    mutationFn: () => DocumentApi.agreementPersonalData(data.id),
    onError: onAxiosError,
    onSuccess: (blob) => genDownloadLink(blob, `Согласие на перс данные.pdf`),
  });

  const downloadFileMutation = useMutation({
    mutationFn: (id: string) => FilesApi.download(id),
    onSuccess: (blob) => genDownloadLink(blob, download),
    onError: onAxiosError,
  });

  const deleteFileMutation = useMutation({
    mutationFn: (id: string) => FilesApi.delete(id),
    onSuccess: () => {
      toast.success('Файл удален');
      client.refetchQueries({ queryKey: [FilesApi.KEY, 'getFiles', data.id] });
    },
    onError: onAxiosError,
  });

  if (!files) return null;

  return (
    <>
      <DialogCommon
        title={
          <div className="flex items-center justify-between">
            <span>Файлы</span>
            <div className="relative z-50 h-8 w-40">
              <div className="absolute top-0">
                <Menu>
                  <MenuButton className="inline-flex w-40 items-center gap-2 rounded bg-brand-2 p-2 px-3 py-1.5 text-sm/6 font-semibold text-white flex-center hover:bg-brand-2/90">
                    Сгенерировать
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="size-4 pr-2 text-white"
                    />
                  </MenuButton>
                  <MenuItems className="mt-2 w-52 origin-top-right rounded border border-brand-2 bg-brand-2 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0">
                    <MenuItem>
                      <Button
                        className="rounded bg-brand-2 px-2 text-xs text-white flex-center hover:bg-brand-2/90"
                        onClick={() => debtCertificateMutation.mutate()}
                      >
                        Справка о задолженности
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        className=" rounded bg-brand-2 px-2 text-xs text-white flex-center hover:bg-brand-2/90"
                        onClick={() => agreementPurchaseMutation.mutate()}
                      >
                        Согласие на приобретение
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        className=" rounded bg-brand-2 px-2 text-xs text-white flex-center hover:bg-brand-2/90"
                        onClick={() => agreementPersonalDataMutation.mutate()}
                      >
                        Согласие на перс данные
                      </Button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <table className="mt-3 w-full text-left text-sm text-brand-1">
          <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {['Имя файла', 'Дата загрузки', 'Действия'].map((item) => (
                <th key={item} scope="col" className="px-6 py-3 text-center">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr
                key={file.id}
                className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
              >
                <td className="px-6 py-4 text-center">{file.name}</td>
                <td className="px-6 py-4 text-center">{file.createdAt}</td>
                <td className="px-6 py-4 text-center">
                  <Button
                    className="mx-auto rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                    onClick={() => {
                      downloadFileMutation.mutate(file.id);
                      setDownload(
                        `${file.name.split('.')[0]}.${file.extension}`,
                      );
                    }}
                  >
                    скачать
                  </Button>
                  <Button
                    className="mx-auto mt-2 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                    onClick={() => {
                      deleteFileMutation.mutate(file.id);
                    }}
                  >
                    удалить
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          onClick={() => setIsOpenAddFile(true)}
          className="mx-auto mt-2 w-52 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
        >
          загрузить файлы
        </Button>
      </DialogCommon>
      <DialogCommon
        title="Загрузка файлов"
        isOpen={isOpenAddFile}
        onClose={() => setIsOpenAddFile(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field className="mt-3">
            <Label className="text-sm/6 font-medium text-white">Выбрать</Label>
            <Input
              {...register('file')}
              type="file"
              className={cx(
                'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                'focus:outline-none',
              )}
            />
          </Field>
          <div className="mt-4 flex gap-2">
            <Button
              className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
              onClick={(event) => {
                event.preventDefault();
                setIsOpenAddFile(false);
              }}
            >
              Отмена
            </Button>
            <SharedButton
              className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
              disabled={uploadFileMutation.isPending}
            >
              Загрузить
            </SharedButton>
          </div>
        </form>
      </DialogCommon>
    </>
  );
};
