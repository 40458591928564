import { DialogTitle } from '@headlessui/react';
import type { PropsWithChildren, ReactElement } from 'react';

import { DialogSimple } from 'shared/ui';

export type DialogCommonProps = {
  title: string | ReactElement;
  isOpen: boolean;
  onClose: () => void;
} & PropsWithChildren;

export type DialogCommonPropsWithData<DataType> = Omit<
  DialogCommonProps,
  'title'
> & {
  data: DataType;
};

export const DialogCommon = ({
  onClose,
  isOpen,
  title,
  children,
}: DialogCommonProps) => {
  return (
    <DialogSimple isOpen={isOpen} onClose={onClose} className="w-full">
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        {title}
      </DialogTitle>
      {children}
    </DialogSimple>
  );
};
