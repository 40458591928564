import { useQuery } from '@tanstack/react-query';

import { getPaymentDetails } from 'features/view-order/lib';
import type { toggleModalFn } from 'features/view-order/model';
import { useNextPayment } from 'features/view-order/model';

import { Order } from 'entities/order';
import { PaymentPlansApi } from 'entities/payment-plans';

import { Button, Spinner } from 'shared/ui';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

export const DialogPayments = ({
  isOpen,
  onClose,
  toggleModal,
  data: { id, payments },
}: DialogCommonPropsWithData<{ id: string; payments: Order['payments'] }> & {
  toggleModal: toggleModalFn;
}) => {
  const { setNextPayment } = useNextPayment();

  const { data } = useQuery({
    queryKey: [PaymentPlansApi.KEY, 'getPaymentPlansWithTypes', id],
    queryFn: () => PaymentPlansApi.getPaymentPlansWithTypes(id),
  });

  if (!data) return <Spinner />;

  const actualData = getPaymentDetails({
    payments,
    paymentPlan: data.steps,
  });

  return (
    <DialogCommon
      title={
        <div className="flex items-center justify-between">
          <h2>График платежей</h2>
          <Button
            onClick={() => toggleModal('isOpenEdit', true)}
            className="w-fit rounded bg-brand-2 px-2 text-xs text-white flex-center hover:bg-brand-2/90"
          >
            Изменить
          </Button>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <table className="mt-3 w-full text-left text-sm text-brand-1">
        <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
          <tr>
            {[
              'Номер платежа',
              'Дата платежа',
              'Сумма платежа',
              'Оплачено',
              'Действия',
            ].map((item) => (
              <th key={item} scope="col" className="px-6 py-3">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {actualData.map((item, index) => {
            const remainingAmount = item.sum - item.paidAmount;
            return (
              <tr key={index} className="border-b border-gray-700 bg-gray-100">
                <td className="px-6 py-4 text-center">{item.step}</td>
                <td className="px-6 py-4 text-center">{item.date}</td>
                <td className="px-6 py-4 text-center">{item.sum} ₽</td>
                <td className="px-6 py-4 text-center">{item.paidAmount} ₽</td>
                <td className="px-6 py-4 text-center">
                  {!item.isPayed ? (
                    <Button
                      className="rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                      onClick={() => {
                        setNextPayment(remainingAmount);
                        toggleModal('isOpenPay', true);
                      }}
                    >
                      Оплатить
                    </Button>
                  ) : (
                    'Оплачено'
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </DialogCommon>
  );
};
