export const genDownloadLink = (blob: Blob, name: string) => {
  if (!(blob instanceof Blob)) {
    console.error('Переданный объект не является Blob:', blob);
    return;
  }

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(url), 100);
};
