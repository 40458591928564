import type { toggleModalFn } from 'features/view-order/model';

import type { Order } from 'entities/order';

import { Button } from 'shared/ui';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

export const DialogPaymentsHistory = ({
  isOpen,
  onClose,
  data,
  toggleModal,
  setDetailId,
}: DialogCommonPropsWithData<Order> & {
  toggleModal: toggleModalFn;
  setDetailId: (id: string) => void;
}) => {
  return (
    <DialogCommon title="История платежей" isOpen={isOpen} onClose={onClose}>
      {data.payments ? (
        <table className="mt-3 w-full text-left text-sm text-brand-1">
          <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {['Статус', 'Дата платежа', 'Сумма платежа', 'Действия'].map(
                (item) => (
                  <th key={item} scope="col" className="px-6 py-3 text-center">
                    {item}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {data.payments
              //@ts-ignore
              .sort((a, b) => +a.stage > +b.stage)
              .map((item) => {
                return (
                  <tr
                    key={item.id}
                    className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 text-center">{item.status}</td>
                    <td className="px-6 py-4 text-center">{item.date}</td>
                    <td className="px-6 py-4 text-center">{item.paidSum} ₽</td>
                    <th scope="col" className="px-6 py-3">
                      <Button
                        className="rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                        onClick={() => {
                          setDetailId(item.id);
                          toggleModal('isOpenDetails', true);
                        }}
                      >
                        Детализация
                      </Button>
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <p>История пуста</p>
      )}
    </DialogCommon>
  );
};
