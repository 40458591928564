import type { UserFile } from 'entities/files';

import { axiosInstance } from 'shared/api/axios.defaults';

export class FilesApi {
  static readonly KEY = 'files';

  static async getFiles(id: string): Promise<UserFile[]> {
    const { data } = await axiosInstance.get(
      `/files/order-files-metadata?orderId=${id}`,
    );
    return data;
  }

  static async upload(params: any): Promise<{ succes: boolean }> {
    const formData = new FormData();
    formData.append('file', params.file[0]);

    const { data } = await axiosInstance.post(
      `/files/upload-file?ownerId=${params.id}&ownerType=ORDER&fileType=UPLOADED_MERCHANTS`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      },
    );
    return data;
  }

  static async download(id: string): Promise<File> {
    const { data } = await axiosInstance.get(`/files/${id}`, {
      responseType: 'blob',
    });
    return data;
  }

  static async delete(id: string): Promise<File> {
    const { data } = await axiosInstance.delete(`/files/${id}`);
    return data;
  }
}
