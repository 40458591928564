import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';

import { Field, Input, Label } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { toggleModalFn, useNextPayment } from 'features/view-order/model';

import { Order, OrderApi } from 'entities/order';
import { PaymentApi } from 'entities/payment';

import { onAxiosError } from 'shared/lib';
import { Button } from 'shared/ui';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

const schema = yup.object({
  amount: yup.number().required('Обязательное поле'),
});

export const DialogPay = ({
  isOpen,
  onClose,
  data,
  toggleModal,
}: DialogCommonPropsWithData<{ id: string } & Order> & {
  toggleModal: toggleModalFn;
}) => {
  const paymentPlanTotal = data.payment_plan.reduce(
    (prev, next) => prev + next.sum,
    0,
  );
  const paymentPlanPayed = data.payments.reduce(
    (prev, next) => prev + +next.paidSum,
    0,
  );

  const maxAmount = paymentPlanTotal - +paymentPlanPayed;
  const { nextPayment } = useNextPayment();

  const client = useQueryClient();

  const {
    getValues,
    register,
    setError,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const sendSmsMutation = useMutation({
    mutationFn: () =>
      PaymentApi.sendSmsPaymentLink({
        orderId: data.id,
        amount: +getValues('amount'),
      }),
    onSuccess,
    onError: onAxiosError,
  });

  const chargeSequentially = useMutation({
    mutationFn: () =>
      PaymentApi.chargeSequentially({
        orderId: data.id,
        amount: +getValues('amount'),
      }),
    onSuccess,
    onError: onAxiosError,
  });

  const onClick = () => {
    setValue('amount', maxAmount);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue('amount', +String(value).replace(/[^0-9]/g, ''));
  };

  const validateAmount = (value: number) => {
    const numericValue = +String(value).replace(/[^0-9]/g, '');
    if (numericValue > maxAmount) {
      setError('amount', {
        message: `Значение не должно превышать ${maxAmount}`,
      });
      return;
    }
    return true;
  };

  function onSuccess() {
    toast.success('Оплата прошла успешно');
    client.refetchQueries({ queryKey: [OrderApi.KEY, 'getOrders', data.id] });
  }

  const onSubmitSendSms = handleSubmit((data) => {
    if (!validateAmount(data.amount)) return;
    // @ts-ignore
    sendSmsMutation.mutate(data.amount);
  });

  const onSubmitChargeSequentially = handleSubmit((data) => {
    if (!validateAmount(data.amount)) return;
    // @ts-ignore
    chargeSequentially.mutate(data.amount);
  });

  useEffect(() => {
    setValue('amount', nextPayment);
  }, [nextPayment]);

  return (
    <>
      <DialogCommon title="Оплата" isOpen={isOpen} onClose={onClose}>
        <Field className="mt-3 flex items-center gap-2">
          <Label className="whitespace-nowrap text-sm/6 font-medium text-white">
            Сумма к оплате
          </Label>
          <Input
            {...register('amount', {
              onChange: onChange,
              validate: validateAmount,
            })}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
          <Label className="whitespace-nowrap text-sm/6 font-medium text-white">
            ₽
          </Label>
        </Field>
        {errors['amount'] && (
          <span className="text-rose-700">{errors['amount'].message}</span>
        )}
        <hr className="my-3 opacity-50" />
        <div className="mt-2 flex-wrap gap-2 flex-center">
          <Button
            onClick={onClick}
            className="w-fit rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
          >
            Полное погашение
          </Button>
          <Button
            onClick={() => {
              setValue('amount', nextPayment);
            }}
            className="w-fit rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
          >
            Плановое погашение
          </Button>
        </div>
        <div className="mx-auto mt-2 flex-col flex-wrap gap-2 flex-center">
          <Button
            disabled={sendSmsMutation.isPending}
            className="min-h-10 w-full rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={onSubmitSendSms}
          >
            Отправить клиенту СМС с ссылкой на оплату
          </Button>
          <Button
            disabled={chargeSequentially.isPending}
            className="min-h-10 w-full rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={onSubmitChargeSequentially}
          >
            Попробовать списать с прикрепленных карт
          </Button>
          <Button
            className="min-h-10 w-full rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenPaySequentially', true)}
          >
            Выбрать карту для списания
          </Button>
        </div>
        <Button
          className="mx-auto mt-2 w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
          onClick={onClose}
        >
          Отмена
        </Button>
      </DialogCommon>
    </>
  );
};
