import type { Partner } from 'entities/partner/model';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableResponse } from 'shared/typings';

export class PartnerApi {
  static readonly KEY = 'partner';

  static async create(params: Omit<Partner, 'id'>): Promise<{
    errorMessage: string;
  }> {
    const response = await axiosInstance.post(`/partners/create`, params);
    return response.data;
  }

  static async update(params: Partial<Partner>): Promise<void> {
    const response = await axiosInstance.post(`/partners/update`, params);
    return response.data;
  }

  static async getPartners(): Promise<TableResponse<Partner>> {
    const { data } = await axiosInstance.get(`/partners`);
    if (!!data.content) {
      // @ts-ignore
      data.content = data.content.map((item) => ({ ...item, active: true }));
    }
    return data;
  }
}
