import { create } from 'zustand/react';

interface NextPaymentState {
  nextPayment: number;
  setNextPayment: (nextPayment: number) => void;
}

export const useNextPayment = create<NextPaymentState>((set) => ({
  nextPayment: 0,
  setNextPayment: (nextPayment: number) => {
    set({ nextPayment });
  },
}));
