import type { Order } from 'entities/order';

export const getPaymentDetails = ({
  paymentPlan,
  payments,
}: {
  paymentPlan: Order['payment_plan'];
  payments: Order['payments'];
}) => {
  const planWithPayments = paymentPlan.map((item) => ({
    ...item,
    paidAmount: 0,
  }));

  payments.forEach((payment) => {
    let remainingPayment = payment.paidSum;

    for (let i = 0; i < planWithPayments.length; i++) {
      const planItem = planWithPayments[i];

      if (remainingPayment <= 0) break;

      if (remainingPayment >= planItem.sum - planItem.paidAmount) {
        const amountToPay = planItem.sum - planItem.paidAmount;
        planItem.paidAmount += amountToPay;
        remainingPayment -= amountToPay;
      } else {
        planItem.paidAmount += remainingPayment;
        remainingPayment = 0;
      }
    }
  });

  return planWithPayments;
};
