import { cx } from '@emotion/css';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Input, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';

import { useSortStore } from 'features/sort-buttons/model';

import { formatPhoneNumber } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';
import { Pagination, Spinner } from 'shared/ui';

import { TableRow, TableRowSizeSelect } from './ui';

export const Table = ({
  queryKeys,
  queryFn,
  columns,
  thead,
  renderRow,
  onRowClick,
}: any) => {
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [internalOrderNumber, setInternalOrderNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');

  const [phoneNumber, setPhoneNumber] = useState<null | string>('');

  const { direction } = useSortStore();

  const [appliedFilters, setAppliedFilters] = useState({
    search: '',
    internalOrderNumber: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phoneNumber: '',
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: query } = useQuery({
    queryKey: [
      ...queryKeys,
      pagination.pageIndex,
      pagination.pageSize,
      direction,
      { ...appliedFilters },
    ],
    queryFn: () => {
      const isSortActive =
        location.pathname === RoutesPath.ORDERS ||
        location.pathname === RoutesPath.REQUESTS;

      const params: any = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        search: appliedFilters.search,
        firstName,
        lastName,
        middleName,
        internalOrderNumber,
        phoneNumber,
      };

      if (isSortActive && direction) {
        params.sort = `createdAt,${direction}`;
      }

      return queryFn(params);
    },
  });

  const table = useReactTable({
    data: query?.content ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: query?.page.totalPages,
    state: {
      pagination,
      globalFilter: search,
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setSearch,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawInput = e.target.value;
    const phoneNumber = `+${formatPhoneNumber(rawInput).replace(/\D/g, '')}`;
    setPhoneNumber(phoneNumber === '+7' ? null : phoneNumber);
  };

  const onPaste = (e: ChangeEvent<HTMLInputElement> & ClipboardEvent) => {
    e.preventDefault();
    // @ts-ignore
    const pastedText = e.clipboardData.getData('Text');
    const phoneNumber = `+${formatPhoneNumber(pastedText).replace(/\D/g, '')}`;
    setPhoneNumber(phoneNumber === '+7' ? null : phoneNumber);
  };

  const onPaginationChange = (pageIndex: number) => {
    setPagination({ ...pagination, pageIndex });
  };

  const applyFilters = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setAppliedFilters({
      search,
      firstName,
      lastName,
      middleName,
      phoneNumber,
      internalOrderNumber,
    });
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
      {location.pathname === RoutesPath.ORDERS ||
      location.pathname === RoutesPath.REQUESTS ? (
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex items-center gap-1">
            <span className="whitespace-nowrap">Внутренний номер:</span>
            <Input
              type="text"
              value={internalOrderNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z0-9]*$/.test(value)) {
                  setInternalOrderNumber(value);
                }
              }}
              placeholder="Поиск..."
              className={cx(
                'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
                'focus:outline-none',
              )}
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="whitespace-nowrap">Фамилия:</span>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[а-яА-ЯёЁ]*$/.test(value)) {
                  setLastName(value);
                }
              }}
              placeholder="Поиск..."
              className={cx(
                'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
                'focus:outline-none',
              )}
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="whitespace-nowrap">Имя:</span>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[а-яА-ЯёЁ]*$/.test(value)) {
                  setFirstName(value);
                }
              }}
              placeholder="Поиск..."
              className={cx(
                'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
                'focus:outline-none',
              )}
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="whitespace-nowrap">Отчество:</span>
            <Input
              type="text"
              value={middleName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[а-яА-ЯёЁ]*$/.test(value)) {
                  setMiddleName(value);
                }
              }}
              placeholder="Поиск..."
              className={cx(
                'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
                'focus:outline-none',
              )}
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="whitespace-nowrap">Телефон:</span>
            <InputMask
              mask="+7 (999) 999-99-99"
              onChange={onChange}
              onPaste={onPaste}
              value={phoneNumber}
              placeholder="Поиск..."
              className={cx(
                'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
                'focus:outline-none',
              )}
            >
              {(props: any) => (
                <Input {...props} placeholder="+7 (999) 999-99-99" />
              )}
            </InputMask>
          </div>
          <button
            onClick={applyFilters}
            className="size-8 rounded-full bg-brand-1 flex-center hover:bg-brand-1/90"
          >
            <FontAwesomeIcon
              icon={faSearch}
              className="size-5 text-white transition-transform duration-300"
            />
          </button>
        </div>
      ) : (
        <div className="flex gap-2">
          <Input
            type="text"
            value={search}
            onChange={onSearchChange}
            placeholder="Поиск..."
            className={cx(
              'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
              'focus:outline-none',
            )}
          />
        </div>
      )}

      <div className="flex flex-1 flex-col overflow-y-auto">
        <table className="w-full text-left text-sm text-brand-1">
          <thead className="sticky top-0 bg-brand-1 text-xs uppercase text-white">
            {thead}
          </thead>
          <tbody className="h-full overflow-y-auto">
            {!query ? (
              <Spinner />
            ) : (
              table.getRowModel().rows.map((row) => {
                return renderRow ? (
                  renderRow(row.original)
                ) : (
                  <TableRow key={row.id} row={row} onClick={onRowClick} />
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between gap-2">
        <Pagination
          pageIndex={pagination.pageIndex}
          pageCount={query?.page.totalPages ?? 0}
          onChange={onPaginationChange}
        />
        <Field className="flex items-center gap-2">
          <Label className="text-sm/6 font-medium text-brand-1">
            Перейти на страницу:
          </Label>
          <Input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            min={1}
            max={query?.page.totalPages}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              onPaginationChange(page);
            }}
            className={cx(
              'flex w-fit rounded-lg border-none bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
              'focus:outline-none hover:bg-brand-1/5 ',
            )}
          />
        </Field>
        <TableRowSizeSelect setPageSize={table.setPageSize} />
      </div>
    </div>
  );
};
