import { useState } from 'react';

import type { Modals, toggleModalFn } from 'features/view-order/model';

export const useModals = () => {
  const [modals, setModals] = useState<Modals>({
    isOpenPayments: false,
    isOpenPaymentsHistory: false,
    isOpenGoods: false,
    isOpenDetails: false,
    isOpenPay: false,
    isOpenFiles: false,
    isOpenEdit: false,
    isOpenPaySequentially: false,
  });

  const toggleModal: toggleModalFn = (modalName, isOpen) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: isOpen,
    }));
  };

  return { modals, toggleModal };
};
