import type { PaymentPlan } from 'entities/order';

import { axiosInstance } from 'shared/api/axios.defaults';

export class PaymentPlansApi {
  static readonly KEY = 'payment-plans';

  static async getPaymentPlansWithTypes(orderId: string): Promise<{
    orderId: string;
    totalSum: string;
    penaltySum: string;
    steps: PaymentPlan[];
  }> {
    const { data } = await axiosInstance.get(`payment-plans/${orderId}`);
    return data;
  }

  static async updatePaymentStep({
    orderId,
    paymentPlan,
  }: {
    orderId: string;
    paymentPlan: PaymentPlan;
  }): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post(
      `payment-plans/${orderId}/update-step`,
      {
        ...paymentPlan,
      },
    );
    return data;
  }
}
