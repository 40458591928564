import { axiosInstance } from 'shared/api/axios.defaults';

import type { ChargeSequentially, SmsPaymentLinkParams } from './model';

export class PaymentApi {
  static readonly KEY = 'payments';

  static async sendSmsPaymentLink(
    params: SmsPaymentLinkParams,
  ): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post(
      `/payments/send-sms-payment-link`,
      params,
    );
    return data;
  }

  static async chargeSequentially(
    params: ChargeSequentially,
  ): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post(
      `/payments/charge-sequentially`,
      params,
    );
    return data;
  }
}
