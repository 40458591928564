import { Steps } from 'antd';
import { formatDate } from 'date-fns';

import { Description, DialogTitle, Field, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useUserStore } from 'app/store';

import { useModals } from 'features/view-order/hooks';
import {
  DialogDetails,
  DialogEdit,
  DialogFiles,
  DialogGoods,
  DialogMenu,
  DialogPay,
  DialogPaySequentially,
  DialogPayments,
  DialogPaymentsHistory,
} from 'features/view-order/ui';

import { OrderApi } from 'entities/order';
import { StatusTag } from 'entities/status';

import { Button, DialogSimple, Spinner } from 'shared/ui';

type ViewOrderDialogProps = {
  id: string | false;
  onClose: () => void;
};

export const ViewOrderDialog = ({ id, onClose }: ViewOrderDialogProps) => {
  const { modals, toggleModal } = useModals();
  const { authorities } = useUserStore();

  const isAdmin = authorities.includes('ADMIN') ?? false;
  const isFilesButtonVisible =
    authorities.includes('FILES_MANAGEMENT') ?? false;
  const isPaymentButtonVisible =
    authorities.includes('PAYMENT_REQUESTING') ?? false;

  const { data } = useQuery({
    queryFn: () => OrderApi.getOrder(id as string),
    queryKey: [OrderApi.KEY, 'getOrders', id],
  });

  const [detailId, setDetailId] = useState('');

  if (!data) {
    return <Spinner />;
  }

  const renderData = [
    { title: 'Внутренний ID', value: data.internal_order_id },
    { title: 'Внешний ID', value: data.external_order_id },
    { title: 'ФИО', value: data.client_fio },
    {
      title: 'Дата рождения',
      value: formatDate(data.client_birthdate, 'dd.MM.yyyy г.'),
    },
    { title: 'Общая сумма', value: data.sum },
    { title: 'Адрес доставки', value: data.delivery_address },
    { title: 'Название продавца', value: data.merchant_name },
    { title: 'Название кредитного продукта', value: data.credit_product_name },
    { title: 'Телефон', value: data.phone_number },
  ];

  return (
    <DialogSimple isOpen={!!id} onClose={onClose} className="w-full">
      <DialogTitle
        as="h3"
        className="flex items-center gap-3 text-xl font-medium text-white"
      >
        <span>Заказ № {data.internal_order_id}</span>
        <span className="text-gray-400">{data.date}</span>
        {!!data.order_status_history.length ? (
          <span>{data.order_status_history[0].status}</span>
        ) : (
          <StatusTag status={data.status} />
        )}
        <DialogMenu
          data={{
            ...data,
            id: id as string,
          }}
        />
      </DialogTitle>
      <div className="mt-3 flex flex-col gap-4">
        <div className="grid grid-cols-[66.6666666666666%_1fr] gap-2">
          <div className="mt-3 grid h-fit grid-cols-2 gap-3">
            {renderData.map((item) => (
              <Field key={item.title}>
                <Label className="text-sm font-medium text-white">
                  {item.title}
                </Label>
                <Description className="text-sm/6 text-white/50">
                  {item.value}
                </Description>
              </Field>
            ))}
          </div>
          <Steps
            progressDot
            current={3}
            direction="vertical"
            items={data.order_status_history.map((item) => ({
              title: item.status,
              description: item.date,
            }))}
          />
        </div>
        <div className="mx-auto flex-wrap gap-2 flex-center">
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenGoods', true)}
          >
            Товары по заказу
          </Button>
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenPayments', true)}
          >
            График платежей
          </Button>
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => toggleModal('isOpenPaymentsHistory', true)}
          >
            История платежей
          </Button>
        </div>
        <div className="mx-auto flex-wrap gap-2 flex-center">
          {(isAdmin || isPaymentButtonVisible) && (
            <Button
              className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
              onClick={() => toggleModal('isOpenPay', true)}
            >
              Оплата
            </Button>
          )}
          {(isAdmin || isFilesButtonVisible) && (
            <Button
              className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
              onClick={() => toggleModal('isOpenFiles', true)}
            >
              Файлы
            </Button>
          )}
        </div>
      </div>
      <DialogGoods
        data={data}
        isOpen={modals.isOpenGoods}
        onClose={() => toggleModal('isOpenGoods', false)}
      />
      <DialogPayments
        isOpen={modals.isOpenPayments}
        onClose={() => toggleModal('isOpenPayments', false)}
        toggleModal={toggleModal}
        data={{
          payments: data.payments,
          id: id as string,
        }}
      />
      <DialogPaymentsHistory
        setDetailId={setDetailId}
        isOpen={modals.isOpenPaymentsHistory}
        onClose={() => toggleModal('isOpenPaymentsHistory', false)}
        toggleModal={toggleModal}
        data={data}
      />
      <DialogPay
        isOpen={modals.isOpenPay}
        onClose={() => toggleModal('isOpenPay', false)}
        toggleModal={toggleModal}
        data={{
          ...data,
          id: id as string,
        }}
      />
      <DialogFiles
        isOpen={modals.isOpenFiles}
        onClose={() => toggleModal('isOpenFiles', false)}
        data={{
          ...data,
          id: id as string,
        }}
      />
      {!!detailId && (
        <DialogDetails
          detailId={detailId}
          isOpen={modals.isOpenDetails}
          onClose={() => toggleModal('isOpenDetails', false)}
          data={data}
        />
      )}
      <DialogEdit
        isOpen={modals.isOpenEdit}
        onClose={() => toggleModal('isOpenEdit', false)}
        toggleModal={toggleModal}
        data={{
          id: id as string,
        }}
      />
      <DialogPaySequentially
        isOpen={modals.isOpenPaySequentially}
        onClose={() => toggleModal('isOpenPaySequentially', false)}
        toggleModal={toggleModal}
        data={{
          client_id: data.client_id,
          id: id as string,
        }}
      />
    </DialogSimple>
  );
};
