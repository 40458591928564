import type { Order } from 'entities/order';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

export const DialogGoods = ({
  isOpen,
  onClose,
  data,
}: DialogCommonPropsWithData<Order>) => {
  return (
    <DialogCommon title="Товары по заказу" isOpen={isOpen} onClose={onClose}>
      <div className="mt-2 overflow-x-auto">
        <table className="w-full text-left text-sm text-brand-1">
          <thead className=" top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {[
                'Номер',
                'Категория',
                'Артикль',
                'Цена',
                'Сумма',
                'Не доставлено',
                'Отменено',
                'Выдано',
                'Возват',
              ].map((item) => (
                <th
                  key={item}
                  scope="col"
                  className="whitespace-nowrap px-6 py-3 text-center"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.cart.data.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="h-fit cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                >
                  <td className="px-6 py-4 text-center">{item.id}</td>
                  <td className="px-6 py-4 text-center">{item.category}</td>
                  <td className="px-6 py-4 text-center">{item.article}</td>
                  <td className="px-6 py-4 text-center">{item.price} ₽</td>
                  <td className="px-6 py-4 text-center">{item.actual_sum} ₽</td>
                  <td className="px-6 py-4 text-center">
                    {item.qty_not_distributed}
                  </td>
                  <td className="px-6 py-4 text-center">{item.qty_canceled}</td>
                  <td className="px-6 py-4 text-center">{item.qty_issued}</td>
                  <td className="px-6 py-4 text-center">{item.qty_returned}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DialogCommon>
  );
};
