import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UUID } from 'uuid-generator-ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { DialogCommonPropsWithData } from 'features/view-order/ui/dialog-common';

import { Order, OrderApi } from 'entities/order';

import { onAxiosError } from 'shared/lib';
import { Button } from 'shared/ui';

export const DialogMenu = ({
  data,
}: Partial<DialogCommonPropsWithData<{ id: string } & Order>>) => {
  const cancelOrderMutation = useMutation({
    mutationFn: () => {
      const itemForCancel = {
        operation_id: UUID.createUUID(),
        items: data!.cart.data
          .filter(
            (item) =>
              item.actual_quantity > 0 ||
              (item.qty_canceled === 0 &&
                item.qty_issued === 0 &&
                item.qty_returned === 0),
          )
          .map((item) => ({
            item_id: item.id,
            category: item.category,
            name: item.name,
            article: item.article,
            quantity: item.actual_quantity,
            price: item.price,
            sum: item.actual_sum,
          })),
      };

      return OrderApi.cancel(data!.id, itemForCancel);
    },
    onSuccess: () => {
      toast.success('Заказ отменен');
    },
    onError: onAxiosError,
  });

  const issueOrderMutation = useMutation({
    mutationFn: () => {
      const itemForIssue = {
        operation_id: UUID.createUUID(),
        items: data!.cart.data
          .filter(
            (item) =>
              item.qty_not_distributed > 0 ||
              item.qty_issued < item.actual_quantity,
          )
          .map((item) => ({
            item_id: item.id,
            category: item.category,
            name: item.name,
            article: item.article,
            quantity: item.actual_quantity,
            price: item.price,
            sum: item.actual_sum,
          })),
      };

      return OrderApi.issue(data!.id, itemForIssue);
    },
    onSuccess: () => {
      toast.success('Успешно');
    },
    onError: onAxiosError,
  });

  return (
    <div className="relative z-50 h-8 w-40">
      <div className="absolute top-0">
        <Menu>
          <MenuButton className="inline-flex w-32 items-center gap-2 rounded bg-brand-2 p-2 px-3 py-1.5 text-sm/6 font-semibold text-white flex-center hover:bg-brand-2/90">
            Действия
            <FontAwesomeIcon
              icon={faChevronDown}
              className="size-4 pr-2 text-white"
            />
          </MenuButton>
          <MenuItems className="mt-2 w-40 origin-top-right rounded border border-brand-2 bg-brand-2 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0">
            <MenuItem>
              <Button
                className="rounded bg-brand-2 px-2 text-xs text-white flex-center hover:bg-brand-2/90"
                onClick={() => cancelOrderMutation.mutate()}
                disabled={cancelOrderMutation.isPending}
              >
                Отменить
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                className="rounded bg-brand-2 px-2 text-xs text-white flex-center hover:bg-brand-2/90"
                onClick={() => issueOrderMutation.mutate()}
                disabled={issueOrderMutation.isPending}
              >
                Выдать
              </Button>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </div>
  );
};
