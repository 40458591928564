import { create } from 'zustand/react';

type Authority = string;
type Authorities = Authority[];

interface UserStore {
  authorities: Authorities;
  setAuthorities: (authorities: Authorities) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  authorities: [],
  setAuthorities: (authorities: Authorities) => {
    set({ authorities });
  },
}));
