import type { Card, ChargeSequentially, PaymentParams } from 'entities/card/model';

import { axiosInstance } from 'shared/api/axios.defaults';

export class CardApi {
  static readonly KEY = 'card';

  static async getCards(clientId: string): Promise<Card[]> {
    const { data } = await axiosInstance.get(`/cards?clientId=${clientId}`);
    return data.sort((a: Card, b: Card) => {
      if (a.isDefault) return -1;
      if (b.isDefault) return 1;
      return 0;
    });
  }

  static async addCard(): Promise<string> {
    const { data } = await axiosInstance.post(`/cards`);
    return data;
  }

  static async makeDefaultCard(id: string): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post(`/cards/${id}/make-default`);
    return data;
  }

  static async deleteCard(id: string): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.delete(`/cards/${id}`);
    return data;
  }

  static async charge(params: PaymentParams): Promise<string> {
    const { data } = await axiosInstance.post(
      `/cards/${params.id}/charge`,
      params,
    );
    return data;
  }

  static async chargeSequentially(params: ChargeSequentially): Promise<string> {
    const { data } = await axiosInstance.post(
      `/cards/charge-sequentially`,
      params,
    );
    return data;
  }
}
