import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InferType } from 'yup';

import { Input } from '@headlessui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import type { PaymentPlan } from 'entities/order';
import { PaymentPlansApi } from 'entities/payment-plans';

import { onAxiosError } from 'shared/lib';
import { Button } from 'shared/ui';

import type { DialogCommonPropsWithData } from '../ui/dialog-common';
import { DialogCommon } from '../ui/dialog-common';

const schema = yup.object().shape({
  payments: yup
    .array()
    .of(
      yup.object().shape({
        step: yup.number().required(),
        date: yup.string().required(),
        sum: yup.number().min(1, 'Сумма должна быть больше 0').required(),
        type: yup.string().oneOf(['PENALTY', 'REGULAR']).required(),
        isNew: yup.boolean().required(),
        isPayed: yup.boolean(),
      }),
    )
    .test(
      'sorted-dates',
      'Даты должны идти в порядке возрастания',
      (payments) => {
        if (!payments || payments.length < 2) return true;
        return payments.every((payment, index) => {
          if (index === 0) return true;
          return new Date(payments[index - 1].date) <= new Date(payment.date);
        });
      },
    ),
});

const formatDate = (date: string, reverse = false) =>
  reverse
    ? date.replaceAll('.', '-').split('-').reverse().join('-')
    : date.replaceAll('-', '.').split('.').reverse().join('.');

export const DialogEdit = ({
  isOpen,
  onClose,
  data: { id },
}: DialogCommonPropsWithData<{ id: string }>) => {
  const client = useQueryClient();

  const { data: paymentPlans } = useQuery({
    queryKey: [PaymentPlansApi.KEY, 'getPaymentPlansWithTypes', id],
    queryFn: () => PaymentPlansApi.getPaymentPlansWithTypes(id),
  });

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { payments: [] },
  });

  const payments = watch('payments');

  useEffect(() => {
    if (!errors || !errors.payments) return;
    Object.values(errors.payments).map(({ message }) =>
      toast.error(message, {
        toastId: 'update-payment-steps-error',
      }),
    );
  }, [errors]);

  useEffect(() => {
    if (!paymentPlans) return;
    setValue(
      'payments',
      paymentPlans.steps.map((item) => ({
        step: item.step,
        date: formatDate(item.date.split('T')[0], true),
        sum: item.sum,
        type: item.type!,
        isNew: false,
      })),
    );
  }, [paymentPlans, setValue]);

  const updatePaymentStepMutation = useMutation({
    mutationFn: (paymentPlan: PaymentPlan) =>
      PaymentPlansApi.updatePaymentStep({
        orderId: id,
        paymentPlan: {
          ...paymentPlan,
          sum: Number(paymentPlan.sum).toFixed(2),
          isPayed: paymentPlan.isPayed ?? false,
        },
      }),
    onError: onAxiosError,
  });

  const onSuccess = () => {
    toast.success('График изменен', {
      toastId: 'update-payment-steps-success',
    });
    client.refetchQueries({
      queryKey: [PaymentPlansApi.KEY, 'getPaymentPlansWithTypes', id],
    });
  };

  const updateSinglePaymentStepMutation = useMutation({
    mutationFn: (paymentPlan: PaymentPlan) =>
      PaymentPlansApi.updatePaymentStep({
        orderId: id,
        paymentPlan: {
          ...paymentPlan,
          sum: Number(paymentPlan.sum).toFixed(2),
          isPayed: paymentPlan.isPayed ?? false,
        },
      }),
    onSuccess,
    onError: onAxiosError,
  });

  const addPaymentRow = () => {
    setValue('payments', [
      ...payments!,
      {
        step: payments!.length + 1,
        date: new Date().toISOString().split('T')[0],
        sum: 0,
        type: 'REGULAR',
        isNew: true,
      },
    ]);
  };

  const deletePaymentRow = (index: number) => {
    setValue(
      'payments',
      payments!.filter((_, i) => i !== index),
    );
  };

  const updatePaymentSteps = async (formData: InferType<typeof schema>) => {
    if (!paymentPlans) return;

    const isSorted = formData.payments!.every((payment, index, arr) => {
      if (index === 0) return true;
      return new Date(arr[index - 1].date) <= new Date(payment.date);
    });

    if (!isSorted) {
      toast.error('Даты платежей должны идти в порядке возрастания');
      formData.payments!.forEach((_, index) => {
        setError(`payments.${index}.date`, {
          type: 'manual',
          message: 'Дата должна быть позже предыдущей',
        });
      });
      return;
    }

    const changedPayments = formData
      .payments!.filter((newItem) => {
        const oldItem = paymentPlans.steps.find((p) => p.step === newItem.step);
        if (!oldItem) return true;

        return (
          oldItem.date !== formatDate(newItem.date) ||
          Number(oldItem.sum) !== Number(newItem.sum) ||
          oldItem.type !== newItem.type
        );
      })
      .map(({ isNew, ...rest }) => rest);

    if (changedPayments.length === 0) {
      toast.info('Изменений нет', {
        toastId: 'update-payment-steps',
      });
      return;
    }

    try {
      await Promise.all(
        changedPayments.map((step) => {
          const oldItem = paymentPlans.steps.find((p) => p.step === step.step);
          return updatePaymentStepMutation.mutateAsync({
            ...step,
            date: formatDate(step.date),
            isPayed: oldItem?.isPayed ?? false,
          } as unknown as PaymentPlan);
        }),
      );
      onSuccess();
    } catch (error) {
      console.error('Ошибка при обновлении:', error);
      toast.error('Ошибка при обновлении');
    }
  };

  return (
    <DialogCommon
      title={
        <div className="flex items-center justify-between">
          <h2>Изменение графика платежей</h2>
          <div className="flex items-center gap-2">
            <Button
              onClick={addPaymentRow}
              className="rounded bg-brand-2 px-2 text-xs text-white"
            >
              Добавить новую строку
            </Button>
            <Button
              onClick={handleSubmit(updatePaymentSteps)}
              className="rounded bg-brand-2 px-2 text-xs text-white"
            >
              Сохранить
            </Button>
          </div>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="mt-2 overflow-x-auto">
        <form onSubmit={handleSubmit(updatePaymentSteps)}>
          <table className="mt-3 w-full text-left text-sm text-brand-1">
            <thead className=" bg-brand-1 text-xs uppercase text-white">
              <tr>
                {[
                  'Номер платежа',
                  'Дата платежа',
                  'Сумма платежа',
                  'Тип',
                  'Статус',
                  'Действия',
                ].map((item) => (
                  <th key={item} className="px-6 py-3 text-center">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {payments.map((item, index) =>
                item.isPayed ? (
                  <tr
                    key={index}
                    className="border-b border-gray-700 bg-gray-100"
                  >
                    <td className="px-6 py-4 text-center">{item.step}</td>
                    <td className="px-6 py-4 text-center">{item.date}</td>
                    <td className="px-6 py-4 text-center">{item.sum} ₽</td>
                    <td className="px-6 py-4 text-center">
                      {item.type === 'PENALTY' ? 'Штраф' : 'Плановый'}
                    </td>
                    <td className="px-6 py-4 text-center text-green-600">
                      Оплачено
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={index}
                    className="border-b border-gray-700 bg-white hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 text-center">{item.step}</td>
                    <td className="px-6 py-4 text-center">
                      <Controller
                        name={`payments.${index}.date`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="date"
                            className="rounded border-2 border-brand-1 text-center"
                            {...field}
                          />
                        )}
                      />
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-center">
                      <Controller
                        name={`payments.${index}.sum`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="number"
                            className="rounded border-2 border-brand-1 text-center"
                            {...field}
                          />
                        )}
                      />
                      &nbsp;₽
                    </td>
                    <td className="px-6 py-4 text-center">
                      <Controller
                        name={`payments.${index}.type`}
                        control={control}
                        render={({ field }) => (
                          <select
                            className="rounded border-2 border-brand-1 text-center"
                            {...field}
                          >
                            <option value="PENALTY">Штраф</option>
                            <option value="REGULAR">Плановый</option>
                          </select>
                        )}
                      />
                    </td>
                    <td className="px-6 py-4 text-center">Не оплачен</td>
                    <td className="px-6 py-4 text-center">
                      {item.isNew && (
                        <div className="flex items-center gap-2">
                          <Button
                            onClick={() => deletePaymentRow(index)}
                            className="rounded border  border-red-500 text-red-500"
                            style={{
                              padding: '.10rem 1rem',
                            }}
                          >
                            Удалить
                          </Button>
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              const paymentsWithoutIsNew = payments!.map(
                                ({ isNew, ...rest }) => rest,
                              );
                              updateSinglePaymentStepMutation.mutate({
                                ...paymentsWithoutIsNew[index],
                                date: formatDate(
                                  paymentsWithoutIsNew[index].date,
                                ),
                              } as unknown as PaymentPlan);
                            }}
                            className="rounded border  border-blue-500 text-blue-500"
                            style={{
                              padding: '.10rem 1rem',
                            }}
                          >
                            Сохранить
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </form>
      </div>
    </DialogCommon>
  );
};
