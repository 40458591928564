import type { CardItemMapped, Order } from 'entities/order';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableQueryParams, TableResponse } from 'shared/typings';

export class OrderApi {
  static readonly KEY = 'order';

  static async getOrders(
    params: TableQueryParams,
  ): Promise<TableResponse<Order>> {
    const resultParams: Partial<TableQueryParams> = {
      page: params.page,
      size: params.size,
    };

    if (params.firstName) resultParams.firstName = params.firstName;
    if (params.lastName) resultParams.lastName = params.lastName;
    if (params.middleName) resultParams.middleName = params.middleName;
    if (params.phoneNumber) resultParams.phoneNumber = params.phoneNumber;
    if (params.sort) resultParams.sort = params.sort;
    if (params.internalOrderNumber)
      resultParams.internalOrderNumber = params.internalOrderNumber;

    const { data } = await axiosInstance.get(`/orders`, {
      params: resultParams,
    });
    return data;
  }

  static async getOrder(id: string): Promise<Order> {
    const { data } = await axiosInstance.get(`/orders/${id}`);
    return data;
  }

  static async cancel(
    id: string,
    params: {
      operation_id: string;
      items: CardItemMapped[];
    },
  ): Promise<Order> {
    const { data } = await axiosInstance.post(`/orders/${id}/cancel`, params);
    return data;
  }

  static async issue(
    id: string,
    params: {
      operation_id: string;
      items: CardItemMapped[];
    },
  ): Promise<Order> {
    const { data } = await axiosInstance.post(`/orders/${id}/issue`, params);
    return data;
  }
}
