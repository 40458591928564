import './styles/main.scss';

import {
  AuthPage,
  CreditProductPage,
  MerchantPage,
  PartnersPage,
  RequestsPage,
  SettingsPage,
} from 'pages';
import { UUID } from 'uuid-generator-ts';
import { Layout } from 'widgets';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useUserStore } from 'app/store';

import { OrdersPage } from 'pages/orders-page';

import { XSessionIdKey } from 'shared/contants';
import { AuthContext } from 'shared/hooks';
import { getAuthoritiesFromToken } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export const App = () => {
  const [isAuth, setIsAuth] = useState(!!sessionStorage.getItem('accessToken'));
  sessionStorage.setItem(XSessionIdKey, UUID.createUUID());

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContext.Provider
          value={{
            isAuth,
            setIsAuth,
          }}
        >
          <Pages />
        </AuthContext.Provider>
      </BrowserRouter>
      <ToastContainer closeOnClick theme="colored" />
    </QueryClientProvider>
  );
};

const Pages = () => {
  const token = sessionStorage.getItem('accessToken');

  const { authorities, setAuthorities } = useUserStore();

  const { data } = useQuery({
    queryKey: ['authorities', token],
    queryFn: () => getAuthoritiesFromToken(token as string),
    enabled: !!token,
  });

  useEffect(() => {
    if (data && data.length) setAuthorities(data);
  }, [data]);

  return (
    <Routes>
      <Route path={RoutesPath.AUTH} element={<AuthPage />} />
      {authorities && (
        <Route element={<Layout />}>
          <Route path={RoutesPath.ORDERS} element={<OrdersPage />} />
          <Route path={RoutesPath.REQUESTS} element={<RequestsPage />} />
          {authorities.includes('ADMIN') && (
            <>
              <Route path={RoutesPath.SETTINGS} element={<SettingsPage />} />
              <Route path={RoutesPath.MERCHANTS} element={<MerchantPage />} />
              <Route path={RoutesPath.PARTNERS} element={<PartnersPage />} />
              <Route
                path={RoutesPath.PRODUCTS}
                element={<CreditProductPage />}
              />
            </>
          )}
        </Route>
      )}
      <Route path="*" element={<Navigate replace to={RoutesPath.ORDERS} />} />
    </Routes>
  );
};
